// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import ce from './createElement';
import { Component } from './component';
import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import showInternalError from './showInternalError';
import prepareUrl from './prepareUrl';
import addUrlParams from './addUrlParams';
import render from './render';
import escapeHtml from './escapeHtml';

import './double-list-selector.less';

export class DoubleListSelector extends Component {
    _initConfiguration(config) {
        super._initConfiguration(config);
        this._cls = 'doubleListSelect';
        this._name = this._getConfigParam('name', '');
        this._list = this._getConfigParam('list', []);
        this._onChange = this._getConfigParam('onChange', () => {});
        this._dataUrl = this._getConfigParam('dataUrl', null);
        this._selectedMarked = 0;
        this._unselectedMarked = 0;
        this._prevSelectedItem = -1;
        this._isSearchable = this._getConfigParam('isSearchable', false);
        this._isLarge = this._getConfigParam('isLarge', false);
    }

    _initList() {
        const elements = {};
        this._list.forEach((element, index) => {
            element.marked = false;
            if ('undefined' === typeof element.selected) {
                element.selected = false;
            }

            this._initListItem(element, index, true);
            this._initListItem(element, index, false);
            elements[element.id] = element.selected;
        });
        this._originalSelection = elements;
        this._initInputElement(elements);
    }

    _initComponentElement() {
        this._componentElement = document.getElementById(this._applyTargetId);
        this._componentElement.classList.add('double-list-select');
        this._componentElement.classList.add('dls-icons-1');
        if (this._isLarge) {
            this._componentElement.classList.add('dls-large');
        }

        const ajaxLoading = null === this._dataUrl ? ''
            : `<div class="ajax-loading" style="display: none;">${this.lmsg('loadingTitle')}</div>`;

        this._updateComponentElement([
            ce('div.dls-first-box',
                this._isSearchable ? this._getSearchControl('unselected') : null,
                ce('h4', ce('span', this.lmsg('unselectedTitle'))),
                ce('div.dls-box-area', { id: `${this._id}-container-unselected` }, ajaxLoading, ce('ul', { id: `${this._id}-unselected` })),
            ),
            ce('div.dls-second-box',
                this._isSearchable ? this._getSearchControl('selected') : null,
                ce('h4', ce('span', this.lmsg('selectedTitle'))),
                ce('div.dls-box-area', { id: `${this._id}-container-selected` }, ajaxLoading, ce('ul', { id: `${this._id}-selected` })),
            ),
            ce('div.dls-controls', { id: `${this._id}-controls` },
                ce('button.btn.disabled', { type: 'button', id: `${this._id}-submitSelect` }, ce('span.dls-add', '&gt;&gt;')),
                ce('button.btn.disabled', { type: 'button', id: `${this._id}-submitUnselect` }, ce('span.dls-remove', '&lt;&lt;')),
            ),
        ]);
        this._initList();
    }

    _initListItem(element, index, isSelected) {
        let container = null;
        let isHidden = false;
        if (isSelected) {
            container = document.getElementById(`${this._id}-selected`);
            isHidden = !element.selected;
        } else {
            container = document.getElementById(`${this._id}-unselected`);
            isHidden = element.selected;
        }

        const item = ce(
            'div.dls-item-block',
            ce('b', escapeHtml(element.title)),
            element.description ? ce('span', element.description) : null,
            element.icons?.length ?
                ce(
                    'span.dls-icons',
                    element.icons.map(({ src, title }) => ce(
                        'i.icon',
                        ce('img', { src: `${Jsw.skinUrl}${src}`, title }),
                    )),
                ) :
                null,
        );

        const listItem = ce('li', {
            id: `${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`,
            style: isHidden ? 'display: none;' : undefined,
            onclick: event => {
                this._toggleItem(index, isSelected, event);
            },
        }, item);
        render(container, listItem);
    }

    _initInputElement(elements) {
        let inputElement = document.getElementById(`${this._id}-elements`);
        if (!inputElement) {
            inputElement = document.createElement('input');
            inputElement.id = `${this._id}-elements`;
            inputElement.name = this._name;
            inputElement.type = 'hidden';
            this._componentElement.appendChild(inputElement);
        }
        inputElement.value = JSON.stringify(elements);
    }

    _getSearchControl(columnId) {
        return ce('.dls-search',
            ce('input.search-input.search-empty', {
                id: `${this._id}-search-${columnId}`,
                type: 'text',
                autocomplete: 'off',
                value: this.lmsg('searchTitle'),
                onfocus: e => this.onFocus(e.target),
                onblur: e => this.onBlur(e.target),
                onkeyup: e => this.onToggleSearch(columnId, e.target.value),
            }),
            ce('span.search-button.icon-search', {
                id: `${this._id}-search-button-${columnId}`,
                onmousedown: () => this.onResetSearch(columnId),
            }),
        );
    }

    _addEvents() {
        document.getElementById(`${this._id}-submitSelect`).addEventListener('click', this._onSubmitSelect.bind(this));
        document.getElementById(`${this._id}-submitUnselect`).addEventListener('click', this._onSubmitUnselect.bind(this));
    }

    _toggleItem(index, isSelected, event) {
        if (this._list[index].marked) {
            this._unmarkItem(index, isSelected, event);
        } else {
            this._markItem(index, isSelected, event);
        }
    }

    _markItem(index, isSelected, event) {
        if (('undefined' !== typeof event) && event.shiftKey && (-1 !== this._prevSelectedItem)) {
            const startIndex = Math.min(index, this._prevSelectedItem);
            const finishIndex = Math.max(index, this._prevSelectedItem);
            for (let i = startIndex; i < finishIndex; i++) {
                this._markItem(i, isSelected);
            }
        }

        this._prevSelectedItem = index;

        this._list[index].marked = true;
        document.getElementById(`${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`).classList.add('marked');
        this._updateSelectControls(isSelected, true);
    }

    _unmarkItem(index, isSelected) {
        this._list[index].marked = false;
        document.getElementById(`${this._id}-${index}-${isSelected ? 'selected' : 'unselected'}-item`).classList.remove('marked');
        this._updateSelectControls(isSelected, false);
    }

    _onSubmitSelect() {
        this._list.forEach((element, index) => {
            if (element.marked && !element.selected && document.getElementById(`${this._id}-${index}-unselected-item`).style.display !== 'none') {
                this._unmarkItem(index, false);
                element.selected = true;
                this._updateElement(element);
                document.getElementById(`${this._id}-${index}-unselected-item`).style.display = 'none';
                document.getElementById(`${this._id}-${index}-selected-item`).style.display = '';
            }
        });

        this.onChange();
    }

    _onSubmitUnselect() {
        this._list.forEach((element, index) => {
            if (element.marked && element.selected && document.getElementById(`${this._id}-${index}-selected-item`).style.display !== 'none') {
                this._unmarkItem(index, true);
                element.selected = false;
                this._updateElement(element);
                document.getElementById(`${this._id}-${index}-selected-item`).style.display = 'none';
                document.getElementById(`${this._id}-${index}-unselected-item`).style.display = '';
            }
        });

        this.onChange();
    }

    _updateElement(element) {
        const elements = JSON.parse(document.getElementById(`${this._id}-elements`).value);
        elements[element.id] = element.selected;
        this._onChange(elements);
        document.getElementById(`${this._id}-elements`).value = JSON.stringify(elements);
    }

    onChange() {
        // basic empty implementation
    }

    onToggleSearch(columnId, searchString) {
        const isSelected = (columnId === 'selected');
        searchString = searchString.trim().toLowerCase();

        if (searchString.length) {
            this._toggleSearchButtonIcon(columnId, true);
            this._list.forEach(function (element, index) {
                if (isSelected !== element.selected) {
                    return;
                }
                const item = document.getElementById(`${this._id}-${index}-${columnId}-item`);
                if (this._isSearchMatched(element, searchString)) {
                    if (item.style.display === 'none' && element.marked) {
                        this._updateSelectControls(isSelected, true);
                    }
                    item.style.display = '';
                } else {
                    if (item.style.display !== 'none' && element.marked) {
                        this._updateSelectControls(isSelected, false);
                    }
                    item.style.display = 'none';
                }
            }, this);
        } else {
            this._toggleSearchButtonIcon(columnId, false);
            this._showAllItems(columnId);
        }
    }

    _isSearchMatched(element, searchString) {
        const searchIndex = 'undefined' === typeof element.searchIndex
            ? element.title
            : element.searchIndex;

        return -1 !== searchIndex.toLowerCase().indexOf(searchString);
    }

    onFocus(element) {
        if (this.lmsg('searchTitle') === element.value) {
            element.value = '';
            element.classList.remove('search-empty');
        }
    }

    onBlur(element) {
        if ('' === element.value) {
            this._resetSearchControl(element);
        }
    }

    onResetSearch(columnId) {
        this._showAllItems(columnId);
        const element = document.getElementById(`${this._id}-search-${columnId}`);
        this._resetSearchControl(element);
        this._toggleSearchButtonIcon(columnId, false);
    }

    _showAllItems(columnId) {
        const isSelected = (columnId === 'selected');
        this._list.forEach((element, index) => {
            if (isSelected === element.selected) {
                const item = document.getElementById(`${this._id}-${index}-${columnId}-item`);
                if (item.style.display === 'none' && element.marked) {
                    this._updateSelectControls(isSelected, true);
                }
                item.style.display = '';
            }
        });
    }

    _resetSearchControl(element) {
        element.value = this.lmsg('searchTitle');
        element.classList.add('search-empty');
    }

    _toggleSearchButtonIcon(columnId, isActive) {
        document.getElementById(`${this._id}-search-button-${columnId}`).classList[isActive ? 'add' : 'remove']('search-button-clear');
    }

    _updateSelectControls(isSelected, setMarked) {
        let counter = isSelected ? this._selectedMarked : this._unselectedMarked;
        const controlId = isSelected ? 'submitUnselect' : 'submitSelect';

        if (setMarked) {
            counter++;
        } else {
            counter--;
        }

        document.getElementById(`${this._id}-${controlId}`).classList[counter ? 'remove' : 'add']('disabled');

        if (isSelected) {
            this._selectedMarked = counter;
        } else {
            this._unselectedMarked = counter;
        }
    }

    isEmpty() {
        return !this._list.some(function (element) {
            return element.selected;
        });
    }

    reload(params) {
        if (!this._dataUrl) {
            return;
        }

        const reloadUrl = addUrlParams(this._dataUrl, params);
        new Ajax.Request(prepareUrl(reloadUrl), {
            method: 'get',
            onSuccess: this._onReloadSuccess.bind(this),
            onFailure: this._onReloadFailure.bind(this),
            onException: this._onReloadException.bind(this),
            onCreate: this._onReloadCreate.bind(this),
            onComplete: this._onReloadComplete.bind(this),
        });
    }

    _onReloadSuccess(transport) {
        if (!transport.responseText) {
            // :INFO: sometimes happens in FF if you are go to other page during request
            return;
        }

        let data = {};
        try {
            data = JSON.parse(transport.responseText);
        } catch {
            showInternalError(transport.responseText);
            return;
        }

        if (data.list) {
            this._list = data.list;
        } else if (data.status) {
            clearStatusMessages();
            (data.statusMessages || []).forEach(function (message) {
                addStatusMessage(message.status, message.content);
            });
        } else {
            showInternalError('Unable to load list data.');
        }

        this._initList();
    }

    _onReloadFailure(transport) {
        showInternalError(transport.responseText);
    }

    _onReloadException(transport, exception) {
        showInternalError(`${exception}\n${transport.responseText}`);
    }

    _onReloadCreate() {
        this._list = [];
        this._componentElement.querySelectorAll('.dls-box-area').forEach(listArea => {
            listArea.querySelector('ul').innerHTML = '';
            listArea.querySelector('.ajax-loading').style.display = '';
        });
    }

    _onReloadComplete() {
        this._componentElement.querySelectorAll('.dls-box-area').forEach(listArea => {
            listArea.querySelector('.ajax-loading').style.display = 'none';
        });
    }

    reset() {
        this._list.forEach(item => {
            item.selected = this._originalSelection[item.id];
        });

        const list = this._list;
        this._onReloadCreate();
        this._onReloadComplete();
        this._list = list;

        this._initList();
    }
}

// TODO PMT-4391: Plesk migrator is broken in Plesk 17.9.1 after cutting core's classes
DoubleListSelector.subclasses = [];
