// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState } from 'react';

class Confirmation {
    isResolved = false;

    constructor(resolve, { onProceed } = {}) {
        this.resolve = resolve;
        this.onProceed = onProceed;
    }

    proceed(isConfirmed) {
        this.isResolved = true;
        this.resolve(isConfirmed);
        this.onProceed?.();
    }
}

const useConfirmation = () => {
    const [confirmation, setConfirmation] = useState(null);

    useEffect(() => () => {
        if (confirmation && !confirmation.isResolved) {
            confirmation.proceed(false);
        }
    }, [confirmation]);

    const triggerConfirmation = () => new Promise(resolve => {
        const confirmation = new Confirmation(resolve, {
            onProceed: () => setConfirmation(null),
        });
        setConfirmation(confirmation);
    });

    return [
        confirmation,
        triggerConfirmation,
    ];
};

export default useConfirmation;
