// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
/* eslint-disable react/jsx-max-depth */

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Button, Text, Icon, ConsoleOutput, Translate } from '@plesk/ui-library';
import RepairKitAlert from './RepairKitAlert';
import ExceptionDetails from './ExceptionDetails';
import styles from './ErrorPage.module.css';

const descriptions = {
    400: 'Your browser sent a request that this server could not understand. Client sent malformed Host header.',
    401: 'This server could not verify that you are authorized to access the document requested.' +
        ' Either you supplied the wrong credentials (e.g., bad password), or your browser doesn\'t understand how to supply the credentials required.',
    403: 'You do not have permission to access this document.',
    404: 'This page either doesn\'t exist, or it moved somewhere else.',
    405: 'The HTTP verb used to access this page is not allowed.',
    406: 'Client browser does not accept the MIME type of the requested page.',
    407: 'You must be authenticated by a proxy server before the Web server can execute your request.',
    412: 'The request was not completed due to preconditions that are set in the request header.\n' +
        ' Preconditions prevent the requested method from being applied to a resource other than the one intended.',
    414: 'The Web server is refusing to service the request because the requested URL address is too long.',
    415: 'The Web server cannot service the request because the requested file is in a format that the server is configured not to download.',
    500: 'The server encountered an internal error or misconfiguration and was unable to complete your request.\n' +
        ' Please contact the server administrator to inform of the time the error occurred and of anything you might have done that may have caused the error.\n' +
        ' More information about this error may be available in the server error log.',
    501: 'The page you are looking for cannot be displayed because a header value in the request does not match certain configuration settings on the Web server.',
    502: 'Web server received an invalid response while acting as a gateway or proxy server.',
    503: 'The server is temporarily unable to service your request due to maintenance downtime or capacity problems. Please try again later.',
};

const consoleOutputHtml = `<div class="console-output__title">Plesk</div>
<span id="initialize" style="display: none">Initializing…                     [ <span>----</span> ]</span>
<span id="clone" style="display: none">Cloning…                          [ <span>----</span> ]</span>
<span id="message" style="display: none"></span>

<div id="done" style="display: none">
___

Your Plesk is almost ready.
In a few seconds, we'll take you there so that you can get started!
</div>`;

const helpActions = () => (
    <>
        <Button className={styles.action} onClick={() => window.location.reload()}>
            <Translate content="reloadPage" />
        </Button>
        {
            window.history.length > 1 &&
            <Button className={styles.action} onClick={() => window.history.back()}>
                <Translate content="backToPreviousPage" />
            </Button>
        }
        <Button className={styles.action} component="a" href="/">
            <Translate content="homePage" />
        </Button>
    </>
);

const ErrorPage = ({
    code = null,
    message,
    noRepairKit = false,
    kbArticleUrl = null,
    exception = null,
    isNetworkError = false,
}) => (
    <div className={styles.page}>
        <div className={classNames(styles.side, code === '503.1' ? styles.sideMaintenance : '')} />
        <div className={styles.main}>
            {isNetworkError ? (
                <>
                    <header className={styles.header}>
                        <Heading><Text intent="danger"><Translate content="networkError" /></Text></Heading>
                    </header>
                    <main>
                        <div className={styles.description}>
                            <p>
                                <Translate content="networkErrorDescription" />
                                <br />
                                <Translate content="helpActions" />
                            </p>
                            <p />
                        </div>
                        {helpActions()}
                    </main>
                </>
            ) : code === '503.1' ? (
                <>
                    <header className={styles.header}>
                        <Heading component="h1" level={2}>{message}</Heading>
                    </header>
                    <main>
                        <ConsoleOutput wide>
                            {/* eslint-disable-next-line react/no-danger */}
                            <div dangerouslySetInnerHTML={{ __html: consoleOutputHtml }} />
                        </ConsoleOutput>
                        <div className={styles.actions}>
                            <Button className={styles.action} onClick={() => window.location.reload()}>
                                <Translate content="reloadPage" />
                            </Button>
                        </div>
                    </main>
                </>
            ) : (
                <>
                    <header className={styles.header}>
                        {code ? <div><Translate content="title" /></div> : null}
                        <Heading><Text intent="danger">{code ? `${code} ` : null}{exception ? exception.type : message}</Text></Heading>
                    </header>
                    <main>
                        <div className={styles.description}>
                            <p>{exception ? exception.message : descriptions[code]}</p>
                        </div>
                        {exception ? (
                            <ExceptionDetails {...exception} />
                        ) : null}
                        {code && /^5\d\d$/.test(code) && !noRepairKit ? (
                            <RepairKitAlert />
                        ) : null}
                        <div className={styles.actions}>
                            <p>
                                <Translate content="reportErrorMessage" />{' '}
                                <a href="https://www.plesk.com/bug-report/" target="_blank" rel="noopener noreferrer">
                                    <Translate content="visitHelpCenter" />
                                </a>
                            </p>
                            <p><Translate content="helpActions" /></p>
                            {helpActions()}
                            {kbArticleUrl ? (
                                <Button className={styles.action} component="a" href={kbArticleUrl} target="_blank" rel="noopener noreferrer">
                                    <Translate content="searchKbArticle" />
                                </Button>
                            ) : null}
                        </div>
                    </main>
                </>
            )}
            <footer className={styles.footer}>
                <div className={styles.footerLinks}>
                    <a href="https://talk.plesk.com/forums/reports.746/" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/report.svg" />
                        <Translate className={styles.footerLinkText} content="reportProblem" />
                    </a>
                    <a href="https://docs.plesk.com/" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/documentation.svg" />
                        <Translate className={styles.footerLinkText} content="documentation" />
                    </a>
                    <a href="https://kb.plesk.com/" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/knowledge-base.svg" />
                        <Translate className={styles.footerLinkText} content="knowledgeBase" />
                    </a>
                    <a href="https://talk.plesk.com/" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/forum.svg" />
                        <Translate className={styles.footerLinkText} content="forum" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCeU-_6YHGQFcVSHLbEXLNlA" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/youtube.svg" />
                        <Translate className={styles.footerLinkText} content="youTube" />
                    </a>
                    <a href="https://www.facebook.com/Plesk" className={styles.footerLink} target="_blank" rel="noopener noreferrer">
                        <Icon size="32" src="/error_docs/images/facebook.svg" />
                        <Translate className={styles.footerLinkText} content="facebook" />
                    </a>
                </div>
            </footer>
        </div>
    </div>
);

ErrorPage.propTypes = {
    code: PropTypes.string,
    message: PropTypes.string.isRequired,
    noRepairKit: PropTypes.bool,
    kbArticleUrl: PropTypes.string,
    exception: PropTypes.shape({
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }),
    isNetworkError: PropTypes.bool,
};

export default ErrorPage;
