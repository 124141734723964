// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import 'core-js/stable';

/**
 * Workaround for 'delete arrayProto.entries;' from prototype.js
 */
if (!Array.prototype.entries) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.entries = function () {
        let index = 0;
        const arr = this;
        return {
            next() {
                if (index < arr.length) {
                    return { value: [index, arr[index++]], done: false };
                }
                return { value: undefined, done: true };
            },
            [Symbol.iterator]() {
                return this;
            },
        };
    };
}


/**
 * Workaround for Array.from(new Set(['1', '2'])) from prototype.js
 */
// eslint-disable-next-line no-extend-native
Set.prototype.toArray = function () {
    const array = [];
    this.forEach(item => array.push(item));
    return array;
};

/**
 * Workaround for Array.from(new Map([['a', 1], ['b', 2]])) from prototype.js
 */
// eslint-disable-next-line no-extend-native
Map.prototype.toArray = function () {
    const array = [];
    this.forEach((item, key) => array.push([key, item]));
    return array;
};
